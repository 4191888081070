import { FC, useState } from "react";
import { interpolateImages } from "../utils/interpolateImages";
import { buildS3Route } from "../utils/buildS3Route";
import { useQuizState } from "../routes/Quiz";
import { twMerge } from "tailwind-merge";

const Image: FC<{ fileName: string; videoId: string }> = ({
  fileName,
  videoId,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const url = buildS3Route(videoId, fileName);

  return (
    <>
      <span
        className={twMerge(
          "animate-pulse text-neutral-800",
          (!loading || error) && "hidden",
        )}
      >
        Loading image...
      </span>
      <span
        className={twMerge("text-red-600", (!error || loading) && "hidden")}
      >
        Unable to load {fileName}
      </span>
      <img
        className="bg-white p-4 my-2 w-full max-w-screen-sm"
        src={url}
        onError={() => {
          setError(true);
          setLoading(false);
          console.error("Unable to load image at: ", url);
        }}
        onLoad={() => {
          setError(false);
          setLoading(false);
        }}
        onLoadStart={() => setLoading(true)}
      />
    </>
  );
};

export const InterpolatedWithImages: FC<{
  content: string;
}> = ({ content }) => {
  const { quiz } = useQuizState();

  return (
    <>
      {interpolateImages(content, (fileName) => (
        <Image fileName={fileName} videoId={quiz.videoId} />
      ))}
    </>
  );
};
