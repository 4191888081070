import { Quiz } from "../types";
import { buildS3Route } from "./buildS3Route";
import { imgTagSrcRegex } from "./interpolateImages";

const addAllFilenamesToSet = (content: string, s: Set<string>) => {
  for (const match of content.matchAll(imgTagSrcRegex)) {
    const m = match[1];
    if (m) s.add(m);
  }
};

export const getAllImagesFromQuiz = (quiz: Quiz): string[] => {
  const imageFilenames = new Set<string>();

  quiz.questions.forEach((question) => {
    addAllFilenamesToSet(question.description, imageFilenames);

    question.answers.forEach((answer) => {
      addAllFilenamesToSet(answer.description, imageFilenames);
      addAllFilenamesToSet(answer.explanation, imageFilenames);
    });
  });

  return Array.from(imageFilenames).map((filename) =>
    buildS3Route(quiz.videoId, filename),
  );
};
