import { useLoaderData } from "@/lib/react-router-dom";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import type { Answer, Quiz } from "../types";
import { buildS3Route } from "../utils/buildS3Route";
import { getQuizName } from "../utils/quizNames";
import { getApiUri } from "../../../config/apiRoutes";
import { LOGIN_URL } from "../../../config/envVars";

type OptionNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type Options = {
  [num in OptionNumber as `option_${num}`]: string;
};

type OptionExplanations = {
  [num in OptionNumber as `option_${num}_explanation`]: string;
};

type FetchedQuestion = {
  "Question No.": number;
  question_description: string;
  correct_option: OptionNumber;
} & Options &
  OptionExplanations;

type FetchedQuiz = FetchedQuestion[];

const optionNumbers = [1, 2, 3, 4, 5, 6, 7] as const satisfies Readonly<
  OptionNumber[]
>;

const getAnswers = (question: FetchedQuestion): Answer[] => {
  const answers: Answer[] = [];

  optionNumbers.forEach((number) => {
    const description = question[`option_${number}`];
    const explanation = question[`option_${number}_explanation`];
    const isCorrect = question.correct_option === number;

    if (description)
      answers.push({
        id: number.toString(),
        description,
        explanation,
        isCorrect,
      });
  });

  return answers;
};

const massage = (id: string, quiz: FetchedQuiz): Quiz => {
  return {
    name: getQuizName(id),
    videoId: id,
    questions: quiz.map((question) => ({
      id: question["Question No."].toString(),
      correctAnswer: question.correct_option.toString(),
      description: question.question_description,
      answers: getAnswers(question),
    })),
  };
};

const getCookie = (name: string) => {
  const a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return a ? a[1] : "";
};

const isActiveSubscriber = async () => {
  if (!import.meta.env.PROD) {
    return true;
  }

  const memberId = getCookie("membership");
  const isLoggedIn = memberId && memberId !== "";

  if (!isLoggedIn) {
    return false;
  }

  const subscriptionRes = await fetch(getApiUri("getSubscription"), {
    cache: "no-cache",
    credentials: "include",
    method: "GET",
    redirect: "error",
  });

  const subscriptionJson = await subscriptionRes.json();
  const subscriptionStatus = await subscriptionJson.status;

  return subscriptionStatus === "active";
};

export const quizLoader = async (quizId?: string): Promise<Quiz> => {
  if (!quizId) throwJsonRouteError(`Unable to load quiz`, 404);
  if (!(await isActiveSubscriber())) window.location.replace(LOGIN_URL);

  const url = buildS3Route(quizId, "quiz.json");
  const response = await fetch(url);
  const quiz = (await response.json()) as FetchedQuiz; // TODO - bring in shared schema to verify this

  if (!quiz) throwJsonRouteError(`Quiz not found`, 404);

  return massage(quizId, quiz);
};

export const useQuizLoader = () => {
  return useLoaderData<Awaited<ReturnType<typeof quizLoader>>>();
};
