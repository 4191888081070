/*
import { ModalBackground } from "@/components";
import {
  ForgotPassword,
  ForgotPasswordConfirm,
} from "@www-modal/features/forgot-password";
import {
  SignupAccount,
  SignupProfile,
  SignupMembership,
  SignupVerify,
} from "@www-modal/features/signup";
import { Login } from "@www-modal/features/login";
*/
import { routes as quizRoutes } from "../features/quiz";

export const unauthenticatedRoutes = [
  /*
  {
    path: "/login",
    element: <ModalBackground />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "/account",
    element: <ModalBackground />,
    children: [
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "forgot-password/confirm",
        element: <ForgotPasswordConfirm />,
      },
    ],
  },
  {
    path: "/signup",
    element: <ModalBackground />,
    children: [
      {
        path: "",
        element: <SignupAccount />,
      },
      {
        path: "verify",
        element: <SignupVerify />,
      },
      // TODO: This should be authenticated
      {
        path: "membership",
        element: <SignupMembership />,
      },
      // TODO: This should be authenticated
      {
        path: "profile",
        element: <SignupProfile />,
      },
    ],
  },
  */
  ...quizRoutes,
];
