import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

export const AuthContext = createContext({});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [accessToken, setAccessToken_] = useState(
    localStorage.getItem("accessToken"),
  );

  const setAccessToken = (newAccessToken: string) => {
    setAccessToken_(newAccessToken);
  };

  useEffect(() => {
    if (accessToken) {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("accessToken", accessToken);
    } else {
      // delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("accessToken");
    }
  }, [accessToken]);

  const contextValue = useMemo(
    () => ({ accessToken, setAccessToken }),
    [accessToken],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
