import { Answer as AnswerType } from "../types";
import { Explanation } from "./Explanation";
import { twMerge } from "tailwind-merge";

interface AnswerProps {
  answer: AnswerType;
  answerSubmitted: boolean;
  onClick: () => void;
  selected: boolean;
  showExplanation: boolean;
  toggleShowExplanation: (show: boolean) => void;
}

export const Answer = (props: AnswerProps) => {
  const answerClasses = twMerge(
    "basis-full my-2 p-3 border-2 rounded bg-white",
    !props.selected &&
      !props.answerSubmitted &&
      "group-hover:bg-neutral-100 group-hover:border-neutral-300",
    !props.selected && "border-neutral-200 hover:bg-neutral-150",
    props.selected && !props.answerSubmitted && "border-blue-500",
    props.answerSubmitted && props.answer.isCorrect && "border-success-500",
    props.answerSubmitted &&
      props.selected &&
      !props.answer.isCorrect &&
      "border-error-500",
    props.answerSubmitted && "cursor-default",
  );

  const circleClasses = twMerge(
    "w-5 h-5 mt-6 mr-3 border-2 rounded-full",
    !props.answerSubmitted && !props.selected && "group-hover:bg-neutral-100",
    !props.selected && "border-neutral-300",
    props.selected && !props.answerSubmitted && "border-blue-500 bg-blue-100",
    props.answerSubmitted &&
      props.selected &&
      !props.answer.isCorrect &&
      "border-error-500 bg-error-100",
    props.answerSubmitted && props.selected && "bg-neutral-300",
    props.answerSubmitted && "cursor-default",
  );

  const isCorrect = props.answer.isCorrect;

  return (
    <div
      onClick={() => !props.answerSubmitted && props.onClick()}
      className="flex group items-start cursor-pointer select-none"
      data-test-id="answer"
    >
      <div className={circleClasses}></div>
      <div
        className={answerClasses}
        data-correct={isCorrect}
        data-test-id={`answer-${props.answer.id}`}
      >
        <span>{props.answer.description}</span>
        <Explanation
          answerSubmitted={props.answerSubmitted}
          explanation={props.answer.explanation}
          showExplanation={props.showExplanation}
          toggleExplanation={props.toggleShowExplanation}
        />
      </div>
    </div>
  );
};
