export const quizzes = {
  "b5daca73-a7d0-4a43-a0bd-0953584fb5d2": "ECG Interpretation",
  "de2a6779-d1ba-4a04-9c1a-d521fe867bed": "Arrhythmias",
  "44f5963c-7610-40e8-b1c7-d28989d6f612": "Coronary Artery Disease",
  "35736882-0ed6-49f1-8fb1-e7a4cfee1e3e": "Heart Failure",
  "988042e0-7da7-40c2-9336-643e0fc5ce2d": "Cardiomyopathies",
  "f3ef38de-a2e0-4bdd-91a9-3d799bb1d258": "Syncope",
  "c22b308f-46e5-484c-9f8c-2df1bf0004a9": "Heart Sounds & Murmurs",
  "b9f76b46-fede-4aaa-8568-a8f8875be67b": "Atrial Fibrillation",
  "1e42c165-e5f8-4563-91c8-d1a081a4d9c2": "Hyperlipidemia",
  "643782e8-68e8-4440-808e-2cf7753b93a2": "Aortic Diseases",
  "4ceba7fa-84cd-49d5-8b47-37b4809c27e7": "Hypertension",
  "a407eeb8-55f0-4ca0-a4d4-cf5d54d16a1b": "Shock",
  "729d0b92-5713-470d-91d6-152ca44b7eb1": "Valvular Heart Disease",
  "17278525-3dbc-4cd8-8924-4fabe1fd95b8": "Peripheral Arterial Disease",
  "a638dfad-c824-48cd-a18c-81813881b7d8": "Pericardial Diseases",
  "06912075-3760-471c-9327-c5da0b003966": "Asthma",
  "247167b0-72a4-4ebd-9109-4c41bf07a210": "Bronchiectasis",
  "29f66f1d-4db8-411f-993c-9ee98245f429":
    "Acute Respiratory Distress Syndrome (ARDS)",
  "badb2ffa-bca4-454e-a96e-c6de8f1b8838": "Hemoptysis",
  "d6182cb3-176c-4376-95eb-668120962015": "COPD",
  "5aaf3dbd-a875-4e8f-b08f-9f7a87e49426": "Pulmonary Function Tests",
  "3985b2cf-a9a6-471b-afea-f02e70e3e2af": "Pleural Diseases",
  "242c5ad6-a7a5-4090-a6c0-b5fdc2113382": "Pulmonary Hypertension",
  "af7b042d-4683-48e6-9658-84f186082d89": "Restrictive Lung Diseases",
  "689300fc-2816-45eb-98b9-626adb6042bc": "Pulmonary Sleep Disorders",
  "adf6f060-914e-4848-9ba8-9db93567ecf7": "Respiratory Failure",
  "dde7c440-7c3d-46b6-9942-54787e0869b3": "Venous Thromboembolism",
  "5b48ba14-787d-4277-9d44-4b8e13acc881": "Dysphagia",
  "5790de29-906d-4256-9ed3-b9d482cc5d82": "Esophagitis",
  "e1f407f1-1c07-4168-baeb-0de95259f6f9": "Esophageal Motility Disorders",
  "0f5ff6b8-d4b8-4d5c-9475-b235ef10d171": "GERD",
  "8b7ce6e6-1534-4a96-9807-f69aaf59449a": "Stomach Disorders",
  "87a81350-9621-40b8-ae4b-749a2c22f392": "Acute Liver Failure",
  "ec6d4876-47ff-4bde-9b22-c124bfb85ef1": "Cirrhosis",
  "e26d17c4-087a-484b-b14f-52ac04151a22": "Liver Function Tests (LFTs)",
  "c9c8fbb3-f4c8-47e7-9c8b-c03d44088531": "Ascites",
  "d66cb909-3d76-476b-bf48-461606c6bd83": "Inherited Liver Diseases",
  "3ed95d54-d80f-40f7-9fa5-4e8f9e4f9868": "Biliary Tract Diseases",
  "ca5f43b0-992e-4dcc-ac99-cd7b38fd0d77": "Cholangitis",
  "ab032ee3-6a1c-4cbd-b154-8b4b8ede9d44": "Pancreatitis",
  "528ea3b2-2833-483e-b8f7-48ef5e4a150d": "GI Bleed",
  "b38e8510-e2f0-4467-a8be-b069012d237d": "Intestinal Ischemia",
  "b4235bb2-cd70-449c-a4a1-f8ead73d47f6": "Appendicitis",
  "d013779e-3cfe-4e21-9edd-ca4f9923fd36": "Inflammatory Bowel Disease (IBD)",
  "ad67a45d-8238-4085-8d89-e6d8f76f9a7e": "Irritable Bowel Syndrome (IBS)",
  "31541668-3fec-40d8-bcd5-7ef75b0584f4": "Diverticular Diseases",
  "19800829-718f-4d0b-8127-8454281f4c78": "Gastrointestinal Perforation",
  "8abd47a0-4914-4f63-b07d-b4fb12a6d445": "Bowel Obstruction",
  "956b8b52-e0e7-4fce-aae2-8dd8199c0218": "Diarrhea",
  "08d16f96-b1ba-41e2-b513-fda27eb5d92f": "Malabsorption",
  "160a3c61-fbae-431d-89ef-6426686fc725": "Hernias",
  "bfbfba30-9ac1-4d2e-8938-c99126e2e7f8": "Hypothyroidism",
  "267e6988-df16-4c90-a0f0-12906520cef3": "Hyperthyroidism",
  "d81d710b-6594-46e0-b4c0-0a7a4e7889d4": "Parathyroid Disorders",
  "a284285b-44a9-41ca-9432-20a581fdee23": "Pituitary Disorders",
  "b04e6984-c98c-4f1e-a7b6-4fd097460bc2": "Adrenal Insufficiency",
  "4d442d8f-4a28-4ff3-b847-8f4832902e5c": "Cushing’s Syndrome & Disease",
  "b865b5d1-de9e-4822-81c0-07524e4bd915": "Hyperaldosteronism",
  "54fd63cf-3fd8-41fd-b99f-3f3d98390831": "Pheochromocytoma",
  "378e19f0-7cc2-43e0-aceb-a8def444e99c": "Diabetes Insipidus & SIADH",
  "0e3f3b78-4cd3-4bd0-9cf6-c040ba21cf7a": "Obesity & Metabolic Syndrome",
  "5aa0b6eb-3d8b-4fc1-b783-a083725a68d9": "Diabetes Mellitus",
  "af26ad34-4e63-43a1-a1b0-33772012b89f": "Urinary Incontinence",
  "0ce97d01-f015-4840-a271-18bf2b9d9cf8": "Prostate Disorders",
  "91682f1f-ae60-400e-b4f8-4fa574221861": "Penile Disorders",
  "129beced-dbb8-4088-aeac-f6fe9144a4d6": "Testicular/Scrotal Disorders",
} as const;
