import { Button } from "@/components";
import { useQuizState } from "./Quiz";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate } from "react-router-dom";

export const Summary = () => {
  const {
    summary: { total, correct, skipped, understanding },
    dispatch,
  } = useQuizState();

  const navigate = useNavigate();

  const retry = () => {
    dispatch({ type: "reset" });
    navigate("..");
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-lg font-bold">Summary</h1>
      <p className="mb-2 font-light text-sm">Here's how you did</p>
      <div
        className={twMerge(
          "flex flex-row justify-center items-baseline rounded-full p-6 py-2 leading-none border-4 mb-4",
          {
            excellent: "bg-green-100 border-green-300",
            basic: "bg-yellow-100 border-yellow-300",
            poor: "bg-red-100 border-brand-red-sign-up",
          }[understanding],
        )}
      >
        <span className="text-3xl font-bold">
          {Math.round((correct / total) * 100)}
          <span className="font-light text-sm">%</span>
        </span>
      </div>
      <div className="grid grid-cols-2 gap-x-2 text-sm mb-4">
        <div className="text-right">Correct: </div>
        <div className="font-bold">{correct}</div>
        <div className="text-right">Skipped: </div>
        <div className="font-bold">{skipped}</div>
        <div className="text-right">Total: </div>
        <div className="font-bold">{total}</div>
      </div>
      <Link to="/" className="w-96">
        <Button className="mb-2" variant="default">
          Finish
        </Button>
      </Link>
      <Link to=".." className="w-full">
        <Button className="mb-2" variant="outlined">
          Review
        </Button>
      </Link>
      <Button variant="outlined" onClick={retry}>
        Retry
      </Button>
    </div>
  );
};
