interface MoveButtonProps {
  children: React.ReactNode;
  title: string;
  disabled?: boolean;
}

export const MoveButton = (props: MoveButtonProps) => {
  const className =
    "text-neutral-500 hover:text-neutral-800 hover:bg-neutral-200 w-[28px] h-[28px] p-1 rounded";

  return (
    <button title={props.title} className={className} disabled={props.disabled}>
      {props.children}
    </button>
  );
};
