import { FC } from "react";
import { useQuizState } from "./Quiz";
import { Navigate } from "react-router-dom";

export const QuestionIndex: FC = () => {
  const { quiz } = useQuizState();

  const id = quiz.questions[0]?.id;

  if (id === undefined)
    throw new Error(
      "Unable to route to first question in quiz, since first question is not defined",
    );

  return <Navigate to={id} replace={true} />;
};
