import { API_URL } from "./envVars";

export const apiRoutes = {
  createAccount: "users",
  createForgotPassword: "account/forgot_password",
  createSession: "sessions",
  getSubscription: "subscription",
  updateProfile: "account/profile",
};

export const getApiUri = (route: keyof typeof apiRoutes) =>
  `${API_URL}/api/v1/${apiRoutes[route]}`;
