import { ChevronForward } from "@/components";
import { InterpolatedWithImages } from "./InterpolatedWithImages";

interface Props {
  answerSubmitted: boolean;
  explanation: string;
  showExplanation: boolean;
  toggleExplanation: (show: boolean) => void;
}

const ToggleExplanation = (props: Props) => {
  return (
    <div
      className="flex flex-row no-wrap items-center my-2 p-1.5 text-sm font-semibold border border-neutral-300 hover:border-neutral-400 bg-neutral-100 hover:bg-neutral-200 rounded text-neutral-700 cursor-pointer w-fit"
      onClick={() => props.toggleExplanation(!props.showExplanation)}
    >
      <div>{props.showExplanation ? "Hide" : "Show"} Explanation</div>
      <div className="w-[24px] h-[24px] p-1">
        <ChevronForward />
      </div>
    </div>
  );
};

export const Explanation = (props: Props) => {
  return (
    <div>
      {props.answerSubmitted && !props.showExplanation && (
        <ToggleExplanation {...props} />
      )}
      {props.showExplanation && (
        <div className="my-2 p-2 text-sm text-neutral-700">
          <InterpolatedWithImages content={props.explanation} />
        </div>
      )}
    </div>
  );
};
