import { Answer } from "./Answer";
import { Button } from "../../../../../../src/components";
import type { Question as QuestionType } from "../types";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useQuizState } from "../routes/Quiz";
import { InterpolatedWithImages } from "./InterpolatedWithImages";

interface QuestionProps {
  next: string;
  question: QuestionType;
  isLastQuestion: boolean;
}

export const Question = (props: QuestionProps) => {
  const { getQuestionState, dispatch } = useQuizState();

  const questionId = props.question.id;

  const { selected, submitted, visibleExplanations } =
    getQuestionState(questionId);

  const baseButtonClasses =
    "text-center p-3 mt-3 rounded font-bold cursor-pointer select-none";
  const submitClasses = twMerge(
    baseButtonClasses,
    "text-neutral-500 hover:text-neutral-700",
    selected !== undefined &&
      "bg-brand-teal hover:bg-brand-red-glasses text-neutral-200 hover:text-white",
    selected === undefined &&
      "bg-neutral-200 hover:bg-neutral-300 cursor-not-allowed",
  );
  const feedbackClasses = twMerge(
    baseButtonClasses,
    "bg-transparent hover:bg-neutral-200 rounded border-2 border-neutral-200",
    "text-neutral-500",
  );

  return (
    <div>
      <div
        className="mb-3 whitespace-pre-line"
        data-test-id="question-description"
      >
        <InterpolatedWithImages content={props.question.description} />
      </div>
      <div className="mb-3">
        {props.question.answers.map((answer) => (
          <Answer
            answer={answer}
            answerSubmitted={submitted !== undefined}
            key={answer.id}
            onClick={() =>
              dispatch({
                type: "select-answer",
                questionId,
                answerId: answer.id,
              })
            }
            selected={selected === answer.id}
            showExplanation={visibleExplanations.has(answer.id)}
            toggleShowExplanation={() => {
              dispatch({
                type: "toggle-explanation",
                questionId,
                answerId: answer.id,
              });
            }}
          />
        ))}
      </div>
      <div className="flex flex-col gap-y-2">
        {submitted ? (
          <Link to={props.next}>
            <Button className={submitClasses}>
              {props.isLastQuestion ? "See Result Summary" : "Next Question"}
            </Button>
          </Link>
        ) : (
          <>
            <Button
              onClick={() => {
                if (!selected) return;

                dispatch({
                  type: "submit-answer",
                  questionId,
                  answerId: selected,
                });
              }}
              className={submitClasses}
              disabled={!selected}
              data-testid="submit-answer"
            >
              Submit Answer
            </Button>
            <Link to={props.next}>
              <Button
                onClick={() => {}}
                className={feedbackClasses}
                data-testid="skip-question"
                variant="outlined"
              >
                Skip Question
              </Button>
            </Link>
          </>
        )}
        {/*}
        <Button
          onClick={() => {}}
          className={feedbackClasses}
          data-testid="send-question-feedback"
          variant="outlined"
        >
          Send Question Feedback
        </Button>
        {*/}
      </div>
    </div>
  );
};
