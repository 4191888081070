import { RouteObject } from "react-router-dom";
import { Quiz } from "./Quiz";
import { quizLoader } from "../loaders/quizLoader";
import { ErrorComponent } from "@/components";
import { Question } from "./Question";
import { QuestionIndex } from "./QuestionIndex";
import { Summary } from "./Summary";

export const routes: RouteObject[] = [
  {
    path: "quiz/:quizId",
    element: <Quiz />,
    loader: ({ params }) => quizLoader(params.quizId),
    errorElement: <ErrorComponent />,
    children: [
      {
        index: true,
        element: <QuestionIndex />,
      },
      {
        path: ":questionId",
        element: <Question />,
      },
      {
        path: "summary",
        element: <Summary />,
      },
    ],
  },
];
