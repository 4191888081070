import { Link } from "react-router-dom";
import {
  ChevronForward,
  ChevronBack,
} from "../../../../../../../src/components";
import { MoveButton } from "./MoveButton";

interface ProgressControlsProps {
  currentQuestionIndex: number;
  currentQuestionId: string;
  next: string;
  previous: string;
  total: number;
}

export const ProgressControls = (props: ProgressControlsProps) => {
  const progress = ((props.currentQuestionIndex + 1) / props.total) * 100;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between mb-2">
        <Link to={props.previous}>
          <MoveButton title="Previous Question">
            <ChevronBack />
          </MoveButton>
        </Link>
        <div className="text-center">
          <span className="font-semibold text-neutral-600">
            Question {props.currentQuestionIndex + 1} of {props.total}
          </span>
        </div>
        <Link to={props.next}>
          <MoveButton title="Next Question">
            <ChevronForward />
          </MoveButton>
        </Link>
      </div>

      <div className="flex-grow">
        <div className="h-2 bg-neutral-200 rounded overflow-hidden">
          <div
            className="h-full bg-success-600"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
