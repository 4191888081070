import { ReactNode } from "react";
import stringReplace from "react-string-replace";

const imgTagRegex = /(<img.+?data-original-filename="{1,2}.+?"{1,2}.*?\/>)/gm;
export const imgTagSrcRegex =
  /<img.+?data-original-filename="{1,2}(.+?)"{1,2}.*?\/>/gm;

export const interpolateImages = (
  content: string,
  cb: (imgSrc: string) => ReactNode,
): ReactNode => {
  return stringReplace(content, imgTagRegex, (imgTagMatch) => {
    return stringReplace(imgTagMatch, imgTagSrcRegex, (srcMatch) => {
      return cb(srcMatch);
    });
  });
};
