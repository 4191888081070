import { ModalBackground, ModalWindow } from "@/components";
import { FC, useReducer } from "react";
import { useQuizLoader } from "../loaders/quizLoader";
import { Outlet, useOutletContext } from "react-router-dom";
import { Quiz as QuizType } from "../types";
import { preloadImages } from "../utils/preloadImages";
import { getAllImagesFromQuiz } from "../utils/getAllImagesFromQuiz";

type QuestionId = string;
type AnswerId = string;

type Action =
  | {
      type: "submit-answer" | "select-answer" | "toggle-explanation";
      questionId: QuestionId;
      answerId: AnswerId;
    }
  | {
      type: "reset";
    };

type QuestionState = {
  submitted: AnswerId | undefined;
  selected: AnswerId | undefined;
  visibleExplanations: Set<AnswerId>;
};

type State = Map<QuestionId, QuestionState>;

const getQuestionStateFn =
  (state: State) =>
  (questionId: QuestionId): QuestionState => {
    return (
      state.get(questionId) ?? {
        submitted: undefined,
        selected: undefined,
        visibleExplanations: new Set<AnswerId>(),
      }
    );
  };

const reducer = (state: State, action: Action): State => {
  const getQuestionState = getQuestionStateFn(state);

  switch (action.type) {
    case "select-answer": {
      return new Map(state).set(action.questionId, {
        ...getQuestionState(action.questionId),
        selected: action.answerId,
      });
    }
    case "submit-answer": {
      return new Map(state).set(action.questionId, {
        ...getQuestionState(action.questionId),
        submitted: action.answerId,
      });
    }
    case "toggle-explanation": {
      const { visibleExplanations, ...questionState } = getQuestionState(
        action.questionId,
      );
      const visible = new Set(visibleExplanations);
      if (visible.has(action.answerId)) {
        visible.delete(action.answerId);
      } else {
        visible.add(action.answerId);
      }

      return new Map(state).set(action.questionId, {
        ...questionState,
        visibleExplanations: visible,
      });
    }
    case "reset": {
      return new Map();
    }
  }
};

const calculateUnderstanding = (
  correct: number,
  total: number,
): OutletContextValue["summary"]["understanding"] => {
  const percentCorrect = (correct / total) * 100;
  if (percentCorrect >= 80) return "excellent";
  if (percentCorrect >= 60) return "basic";

  return "poor";
};

type OutletContextValue = {
  quiz: QuizType;
  dispatch: React.Dispatch<Action>;
  summary: {
    total: number;
    correct: number;
    skipped: number;
    understanding: "excellent" | "basic" | "poor";
  };
  getQuestionState: ReturnType<typeof getQuestionStateFn>;
};

export const Quiz: FC = () => {
  const quiz = useQuizLoader();

  preloadImages(getAllImagesFromQuiz(quiz));

  const [state, dispatch] = useReducer(reducer, new Map());

  const getQuestionState = getQuestionStateFn(state);

  const total = quiz.questions.length;
  const correct = quiz.questions.reduce(
    (count, question) =>
      getQuestionState(question.id).submitted === question.correctAnswer
        ? count + 1
        : count,
    0,
  );

  const outletContextValue: OutletContextValue = {
    quiz,
    dispatch,
    summary: {
      total,
      correct,
      skipped: quiz.questions.reduce((count, question) => {
        return state.get(question.id)?.submitted === undefined
          ? count + 1
          : count;
      }, 0),
      understanding: calculateUnderstanding(correct, total),
    },
    getQuestionState,
  };

  return (
    <ModalBackground
      className="justify-start pt-4"
      closeOnBackgroundClick={false}
    >
      <ModalWindow title={quiz.name} expandable>
        <Outlet context={outletContextValue} />
      </ModalWindow>
    </ModalBackground>
  );
};

export const useQuizState = () => {
  return useOutletContext<OutletContextValue>();
};
