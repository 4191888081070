import { FC } from "react";
import { ProgressControls } from "../components/ProgressControls";
import { Question as QuestionComponent } from "../components/Question";
import { useQuizState } from "./Quiz";
import { useParams } from "react-router-dom";

export const Question: FC = () => {
  const { quiz } = useQuizState();

  const { questionId: questionIdParam } = useParams<{
    questionId: string;
  }>();

  const foundIndex = quiz.questions.findIndex((q) => q.id === questionIdParam);
  const currentIndex = foundIndex !== -1 ? foundIndex : 0;

  const question = quiz.questions[currentIndex];
  const previousQuestion = quiz.questions[currentIndex - 1];
  const nextQuestion = quiz.questions[currentIndex + 1];

  if (question === undefined)
    throw new Error("Unreachable. Question should be defined");

  const previous = `../${previousQuestion?.id ?? question.id}`;
  const next = nextQuestion?.id ? `../${nextQuestion.id}/` : "../summary";
  const isLast = nextQuestion?.id === undefined;

  return (
    <>
      <ProgressControls
        currentQuestionId={question.id}
        currentQuestionIndex={currentIndex}
        next={next}
        previous={previous}
        total={quiz.questions.length}
      />
      <QuestionComponent
        next={next}
        question={question}
        isLastQuestion={isLast}
      />
    </>
  );
};
