import { RouterProvider, createHashRouter } from "react-router-dom";
import { FC } from "react";
import { unauthenticatedRoutes } from "./unauthenticated";
import * as Sentry from "@sentry/react";

const sentryCreateHashRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);

const commonRoutes = [
  {
    path: "/",
    element: <span></span>,
  },
];

const router = sentryCreateHashRouter([
  ...commonRoutes,
  ...unauthenticatedRoutes,
]);

export const AppRoutes: FC = () => {
  return <RouterProvider router={router} />;
};
